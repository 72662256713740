import classnames from 'classnames';
import PropTypes from 'prop-types';

import { HSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import Text from 'bloko/blocks/text';

import paths from 'src/app/routePaths';
import OpenEmployerIcon from 'src/components/Employer/ChameleonHeader/components/OpenEmployerIcon';
import HrBrandIcon from 'src/components/HrBrandIcon';
import translation from 'src/components/translation';
import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import EmployerHHRatingIcon from '../../EmployerHHRatingIcon';

const Content = ({
    trls,
    withoutLogo,
    address,
    site,
    vacanciesCount,
    hrBrand,
    badges,
    industries,
    insider,
    accepted,
    isTrusted,
    colors,
}) => {
    const employerId = useSelector((state) => state.employerInfo.id);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const isZarplata = useIsZarplataPlatform();
    const headerStyle = colors?.header ? { color: `#${colors.header}` } : {};
    const textStyle = colors?.text ? { color: `#${colors.text}` } : {};
    const linkStyle = colors?.link ? { color: `#${colors.link}` } : {};

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    return (
        <div
            className={classnames('employer-sidebar-content', {
                'employer-sidebar-content_without-logo': withoutLogo,
            })}
            style={textStyle}
            data-qa="sidebar-text-color"
        >
            {address && <div className="employer-sidebar-block">{address}</div>}
            {site && accepted && (
                <Link to={site.href} data-qa="sidebar-company-site">
                    <span style={linkStyle}>{site.hostname}</span>
                </Link>
            )}
            {!!vacanciesCount && !isZarplata && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[Content.trls.vacanciesTitle]}
                    </Text>
                    <Link
                        to={`${paths.vacancySearch}?from=employerPage&employer_id=${employerId}`}
                        data-qa="employer-page__employer-vacancies-link"
                        style={linkStyle}
                    >
                        <Conversion
                            value={vacanciesCount}
                            one={trls[Content.trls.vacanciesCountOne]}
                            some={trls[Content.trls.vacanciesCountSome]}
                            many={trls[Content.trls.vacanciesCountMany]}
                            zero={trls[Content.trls.vacanciesCountMany]}
                        />
                    </Link>
                </div>
            )}
            {!!industries?.length && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[Content.trls.scope]}
                    </Text>
                    {industries.map((industry) => {
                        return <p key={industry.id}>{industry.trl}</p>;
                    })}
                </div>
            )}
            {!!insider?.articles?.length && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[Content.trls.interview]}
                    </Text>
                    {insider.articles.map((article) => {
                        return (
                            <Link
                                to={`/interview/${article.code}?employerId=${insider.employerId}`}
                                className="employer-sidebar-interview-url"
                                key={article.code}
                                style={linkStyle}
                            >
                                {article.title}
                            </Link>
                        );
                    })}
                </div>
            )}
            {(hrBrand || employerHHRatingBadge || (isOpenEmployer && isTrusted)) && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[Content.trls.awards]}
                    </Text>
                    <VSpacing default={4} />
                    <HSpacingContainer default={4}>
                        <OpenEmployerIcon size="small" />
                        {employerHHRatingBadge && (
                            <EmployerHHRatingIcon badge={employerHHRatingBadge} size="small" dataQaPrefix="sidebar-" />
                        )}
                        {hrBrand && <HrBrandIcon hrBrand={hrBrand} size="small" dataQa="sidebar-hr-brand-icon" />}
                    </HSpacingContainer>
                </div>
            )}
        </div>
    );
};

Content.trls = {
    vacanciesTitle: 'employer.sidebar.vacancies.title',
    vacanciesCountOne: 'employer.sidebar.vacancies.count.one',
    vacanciesCountSome: 'employer.sidebar.vacancies.count.some',
    vacanciesCountMany: 'employer.sidebar.vacancies.count.many',
    awards: 'employer.sidebar.awards',
    scope: 'employer.sidebar.scope',
    interview: 'employer.page.interview',
};

Content.propTypes = {
    trls: PropTypes.object,

    withoutLogo: PropTypes.bool,
    address: PropTypes.string,
    site: PropTypes.shape({
        hostname: PropTypes.string,
        href: PropTypes.string,
    }),
    vacanciesCount: PropTypes.number,
    hrBrand: PropTypes.object,
    badges: PropTypes.object,
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            trl: PropTypes.string,
        })
    ),
    insider: PropTypes.shape({
        articles: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.number,
                title: PropTypes.string,
            })
        ),
        employerId: PropTypes.number,
    }),
    accepted: PropTypes.bool,
    isTrusted: PropTypes.bool,
    colors: PropTypes.shape({
        background: PropTypes.string,
        header: PropTypes.string,
        link: PropTypes.string,
        text: PropTypes.string,
    }),
};

export default translation(Content);
