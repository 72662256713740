import { useEffect, useCallback, FC, PropsWithChildren, useRef } from 'react';
import { useDispatch } from 'react-redux';

import SessionStorageWrapper from 'bloko/common/storage/SessionStorageWrapper';

import { useSetEmployerVacancyList } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import {
    EmployerVacancyTemplateFilter,
    setEmployerVacancyTemplateFilter,
    allowedCriteria,
} from 'src/models/employerVacancyTemplateFilter';
import { getTemplateCriteria } from 'src/models/employerVacancyTemplateFilter/getTemplateCriteria';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

interface Detail extends EmployerVacancyTemplateFilter {
    currentEmployerId: number;
    siteId: string;
    groupByRegion: boolean;
}

const getEmployerVacancyTemplateFilter = (payload: Detail): EmployerVacancyTemplateFilter =>
    Object.keys(payload).reduce((acc, key) => {
        if (allowedCriteria.includes(key)) {
            return { ...acc, [key]: payload[key] };
        }
        return acc;
    }, {});

interface PayloadFilterParams {
    detail?: Detail;
}

const TemplateFilterProvider: FC<PropsWithChildren> = ({ children }) => {
    const isInitialRef = useRef<boolean>(false);
    const dispatch = useDispatch();
    const { setEmployerVacancyFilter } = useSetEmployerVacancyList();
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const { addNotification } = useNotification();

    const setTemplateFilter = useCallback(
        (payload?: Detail) => {
            if (payload) {
                const { currentEmployerId } = payload;
                const filter = getEmployerVacancyTemplateFilter(payload);
                dispatch(setEmployerVacancyTemplateFilter(filter));
                setEmployerVacancyFilter({
                    ...filter,
                    [CriteriaKey.CurrentEmployerId]: currentEmployerId,
                    [CriteriaKey.HiddenFilters]: getTemplateCriteria(filter),
                });
            } else {
                setEmployerVacancyFilter();
            }
        },
        [dispatch, setEmployerVacancyFilter]
    );

    const setVacanciesBlockFilter = useCallback(() => {
        const filtersSource = SessionStorageWrapper.getItem('VacancyBlockFilter');
        let payloadFilterParams;
        try {
            payloadFilterParams = JSON.parse(filtersSource || '{}') as PayloadFilterParams;
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
        const payload =
            !payloadFilterParams?.detail || Number(employerId) !== Number(payloadFilterParams.detail.currentEmployerId)
                ? undefined
                : payloadFilterParams.detail;
        setTemplateFilter(payload);
    }, [addNotification, employerId, setTemplateFilter]);

    const handleVacanciesBlockFilter: EventListener = useCallback(
        () => setVacanciesBlockFilter(),
        [setVacanciesBlockFilter]
    );

    useEffect(() => {
        window.addEventListener('VacancyBlockFilter', handleVacanciesBlockFilter);
        return () => {
            window.removeEventListener('VacancyBlockFilter', handleVacanciesBlockFilter);
        };
    }, [handleVacanciesBlockFilter]);

    useEffect(() => {
        if (!isInitialRef.current) {
            void setVacanciesBlockFilter();
            isInitialRef.current = true;
        }
    }, [setVacanciesBlockFilter]);

    return <>{children}</>;
};

export default TemplateFilterProvider;
